[data-sidebar='dark'] {
  .sidebar {
    background-color: #192030;

    @include media-breakpoint-up(lg) {
      box-shadow: none;
    }

    &::before {
      background-color: rgba(darken(#192030, 5%), 0.75);
    }

    .sidebar-logo {
      color: #fff;
    }

    .sidebar-header {
      border-bottom-color: rgba(#fff, 0.06);
    }

    .sidebar-body {
      .nav-group {
        border-bottom-color: rgba(#fff, 0.06);
      }

      .nav-label {
        color: rgba(#fff, 0.25);
      }

      > .ps__rail-y {
        background-color: rgba(#fff, 0.1);

        > .ps__thumb-y {
          background-color: rgba(#fff, 0.75);
        }
      }
    }

    .nav-sidebar {
      .nav-link {
        color: rgba(#fff, 0.6);

        &:hover,
        &:focus {
          color: rgba(#fff, 0.85);
        }

        &.active {
          color: $primary;
          font-weight: 400;
        }

        &.has-sub::after {
          color: rgba(#fff, 0.6);
        }
      }

      .nav-sub-link {
        color: rgba(#fff, 0.5);

        &::before {
          background-color: rgba(#fff, 0.4);
        }

        &.active {
          color: rgba(#fff, 0.85);

          &::before {
            background-color: $primary;
          }
        }
      }
    }
  }

  .sidebar-footer {
    background-color: #192030;
    border-top-color: rgba(#fff, 0.06);
    box-shadow: none;

    .dropdown-link {
      color: rgba(#fff, 0.6);

      &:hover,
      &:focus {
        background-color: darken(#192030, 3%);
        color: #fff;
      }
    }
  }

  .sidebar-footer-body {
    h6 a {
      color: #fff;
    }

    p {
      color: rgba(#fff, 0.4);
    }
  }

  .sidebar-footer-menu {
    a {
      color: rgba(#fff, 0.6);

      &:hover,
      &:focus {
        background-color: lighten(#192030, 5%);
        color: #fff;
      }
    }

    hr {
      background-color: #fff;
      opacity: 0.1;
    }
  }
}
