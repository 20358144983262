.figure-overlay {
  position: relative;

  figcaption {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($dark, 0.5);
  }
}
