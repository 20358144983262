.avatar {
  width: $height-base;
  height: $height-base;
  border-radius: 100%;
  display: block;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
    display: block;
  }

  &.online::after,
  &.offline::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 5px;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    box-shadow: 0 0 0 1.5px $body-bg;
  }

  &.online::after {
    background-color: $success;
  }
  &.offline::after {
    background-color: $gray-400;
  }
}

.avatar-rounded {
  border-radius: 8px;
}

.avatar-initial {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $secondary;
  color: #fff;
  border-radius: inherit;
  font-family: $font-family-sans-serif;
  font-size: 16px;
  text-transform: uppercase;
}

.avatar-xs {
  width: $height-base - 16px;
  height: $height-base - 16px;

  .avatar-initial {
    font-size: 9px;
  }

  &.online::after,
  &.offline::after {
    width: 3px;
    height: 3px;
    right: 0;
  }
}

.avatar-sm {
  width: $height-base - 10px;
  height: $height-base - 10px;

  .avatar-initial {
    font-size: 13px;
  }

  &.online::after,
  &.offline::after {
    width: 5px;
    height: 5px;
    right: 3px;
  }
}

.avatar-lg {
  width: $height-base + 10px;
  height: $height-base + 10px;

  .avatar-initial {
    font-size: 24px;
  }

  &.online::after,
  &.offline::after {
    width: 7px;
    height: 7px;
  }
}

.avatar-xl {
  width: $height-base + 25px;
  height: $height-base + 25px;

  .avatar-initial {
    font-size: 32px;
  }

  &.online::after,
  &.offline::after {
    width: 8px;
    height: 8px;
    right: 7px;
    bottom: 2px;
  }
}
