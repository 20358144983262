.main-pricing {
  .btn {
    padding: 10px 20px;
    line-height: 1.2;

    @include media-breakpoint-up(lg) {
      padding: 12px 25px;
    }
  }
}

.pricing-title {
  font-size: 32px;
  font-weight: $font-weight-bold;
  color: $dark;
  letter-spacing: -1px;

  @include media-breakpoint-up(sm) {
    font-size: 36px;
    width: 80%;
  }

  @include media-breakpoint-up(md) {
    font-size: 40px;
    width: 75%;
  }

  @include media-breakpoint-up(lg) {
    font-size: 46px;
  }
  @include media-breakpoint-up(xl) {
    width: 60%;
  }
}

.pricing-text {
  font-size: 16px;
  color: $secondary;
}

.table-pricing {
  min-width: 780px;

  thead th {
    border-bottom-width: 0;
    padding-bottom: 0;
    vertical-align: top;

    span {
      display: block;
      margin-bottom: 10px;
    }

    > small {
      display: block;
      font-size: $font-size-xs;
      font-weight: 400;
      line-height: 1.3;
      color: $gray-700;
    }

    &.selected .pricing-price {
      color: $primary;

      small {
        color: inherit;
      }
    }
  }

  thead th,
  tbody td {
    &:not(:first-child) {
      text-align: center;
    }
    &.selected {
      background-color: rgba($primary, 0.06);
    }
  }

  > :not(:first-child) {
    border-top: 0;
  }

  tbody td {
    padding: 10px 0;
    vertical-align: middle;

    i {
      font-size: 24px;
      font-weight: 700;
      color: $primary;
    }

    .btn {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
    }

    &:first-child {
      text-transform: capitalize;
      font-weight: $font-weight-medium;
      color: $dark;
      padding-right: 10px;
      white-space: nowrap;

      small {
        font-weight: 400;
        text-transform: none;
        color: $secondary;
        display: block;
        line-height: 1.4;
        white-space: normal;
      }

      i {
        font-size: 16px;
        font-weight: 400;
        line-height: 1;
        position: relative;
        top: 1px;
      }
    }

    &:not(:first-child) span {
      display: block;
      padding: 8px 0;
      font-family: $ff-numerals;
      font-weight: $font-weight-medium;
    }
  }

  tr:last-child td {
    border-bottom-width: 0;
    padding: 40px 10px 10px;
  }

  .title td {
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom-width: 1.5px;
    border-bottom-color: $gray-500;

    &:first-child {
      text-transform: uppercase;
      font-weight: $font-weight-bold;
      color: $dark;
    }
  }

  .title:not(:first-child) td {
    padding-top: 25px;
  }
}

.pricing-price {
  font-size: 36px;
  font-weight: $font-weight-bold;
  font-family: $ff-numerals;
  letter-spacing: -1px;
  color: $dark;

  small {
    font-size: 13px;
    font-weight: 400;
    font-family: $font-family-base;
    color: $secondary;
    letter-spacing: -0.2px;
  }
}

//////////// FAQ //////////////

.main-faq {
  .nav-classic .nav-link {
    color: $body-color;
    &:hover {
      color: $primary;
    }
  }
}

.accordion-faq {
  .accordion-item {
    background-color: #fff;
    border: 0;
    border-radius: 6px;
    @include shadow-dreamy($gray-400);

    + .accordion-item {
      margin-top: 5px;
    }
  }

  .accordion-button {
    background-color: transparent;
    font-size: 15px;
    font-weight: $font-weight-semibold;
    color: $primary;
    border-bottom: 0;

    &.collapsed {
      color: $dark;
    }
    &:not(.collapsed) {
      box-shadow: none;
    }
    &:focus {
      box-shadow: none;
    }
  }
}
