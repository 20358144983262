// Bootstrap Overrides

$body-color: #020826;
$body-bg: #fffffe;
$input-bg: #fffffe;

$gray-100: #fffffe;
$gray-200: #f9f4ef;
$gray-300: #eaddcf;
$gray-400: #8c7851;
$gray-500: #716040;
$gray-600: #020826;
$gray-700: #020826;
$gray-800: #020826;
$gray-900: #020826;

// Theme Colors
$color-ui-01: #716040;
$color-ui-02: #8c7851;
$color-ui-03: #020826;
$color-ui-04: #f9f4ef;

$color-tx-01: $gray-900;
$color-tx-02: $gray-700;
$color-tx-03: $gray-600;

$primary: $color-ui-01;
$success: #0cb785;
$teal: #33d685;
$pink: #ea4c89;

// Dark Theme Colors
$dark-primary: #141926;
$dark-highlight: #192030;

// Borders
$border-radius: 0.25rem;
$border-color-dark: lighten($dark-primary, 8%);

// Buttons
$input-btn-padding-y: 0.406rem;

// Fonts
$ff-primary: 'Open Sans', sans-serif;
$ff-secondary: 'Archivo', sans-serif;
$ff-numerals: 'Inter', sans-serif;

$font-family-base: $ff-primary;

$font-weight-base: 400;
$font-weight-light: 300;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$font-size-base: 0.875rem;
$font-size-sm: 0.8125rem;
$font-size-xs: 0.75rem;
$font-size-md: 0.938rem;

// Links
$link-decoration: none;

// Size
$height-header: 72px;
$height-base: 36px;
$sidebar-width: 250px;

// Shadows
$box-shadow: 0 0.5rem 1rem rgba($gray-900, 0.15) !default;
$box-shadow-sm: 0 0.125rem 0.25rem rgba($gray-900, 0.075) !default;
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, 0.175) !default;
$box-shadow-inset: inset 0 1px 2px rgba($gray-900, 0.075) !default;
